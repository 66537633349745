<template>
    <v-container style="max-width:100vw;">
        <!-- Header -->
        <v-toolbar flat class="px-6 py-3">
            <v-toolbar-title>Inventario Físico</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn rounded color="primary" @click="save()" class="elevation-0">Guardar</v-btn>
        </v-toolbar>

        <v-card class="elevation-0 pa-6 pr-10">
            <v-text-field 
                append-icon="mdi-magnify" 
                class="ml-6" 
                flat 
                hide-details 
                label="Buscar" 
                solo 
                background-color=#f5f6fa 
                v-model="searchInput">
            </v-text-field><!-- @keydown.enter="search()" @click:append="scanner()" prepend-inner-icon="mdi-barcode" -->

            <v-card-text>
                <v-list>
                    <v-list-item v-for="(item, index) in itemsList" v-bind:key="index" style="border-bottom:1px #dddddd solid!important;">
                        <v-list-item-title>{{item.name}} {{item.inventory}}</v-list-item-title>
                        <v-list-item-icon class="ma-0">
                            <v-text-field style="transform:translateY(15px)" type="number" v-model="item.inventory" label="Inventario" filled rounded dense small
                            @click:append="preSave(item.id, item.name, item.inventory)" append-icon="mdi-content-save"
                            ></v-text-field>
                        </v-list-item-icon>
                    </v-list-item>
                </v-list>
            </v-card-text>

        </v-card>

        <v-snackbar :color="snackbar.color" v-model="snackbar.show">
            {{ snackbar.message }}
        </v-snackbar>
    </v-container>
</template>

<script>
export default {
    data: () => ({
        searchInput:'',
        inventario:[{
            id:'',
            name:'',
            inventory:''
        }],
        snackbar: {
            show: false,
            message: null,
            color: null
        },
    }),
    computed: {
        itemsList(){
            var items = this.$store.state.item.items.map(id=>{
                return{
                    id:id.id,
                    name:id.name,
                    inventory:this.newInventory(id.id)
                }
            })
            if(this.searchInput!=undefined&&this.searchInput!=''&&this.searchInput!=null){
                return items.filter(item=>item.name.toLowerCase().includes(this.searchInput.toLowerCase()))
            }else{
                return items
            }
        },
        items(){
            return this.$store.state.item.items
        },
        currentUser:{
            get(){
                return this.$store.state.currentUser.user
            }
        } 
    },
    methods: {
        preSave(preId, preName, preInventory){
            var concatenar = {
                id:preId,
                name:preName,
                inventory:preInventory
            }
            this.inventario = this.inventario.concat(concatenar)
            this.inventario = this.removeDuplicates(this.inventario, "id");
        },
        removeDuplicates(originalArray, prop) {
            var newArray = [];
            var lookupObject  = {};
            for(var i in originalArray) {
                lookupObject[originalArray[i][prop]] = originalArray[i];
            }
            for(i in lookupObject) {
                newArray.push(lookupObject[i]);
            }
            return newArray;
        },
        newInventory(id){
            var number = this.inventario.filter(item=>item.id == id).map(item=>item.inventory)[0]
            if(number!=undefined){
                return '(' + number + ')'
            }
        },
        save(){
            var physicalInventory={
                inventory:this.inventario,
                created_by_user_id:this.currentUser,
                authorization: false,
            }
            axios.post("https://intenbackend.unocrm.mx/api/v1/physical_inventory/create",Object.assign(physicalInventory)).then(response=>{
                location.reload();
            }).catch(error => {
                this.snackbar = {
                    message: error.response.data.message,
                    color: 'error',
                    show: true
                }
            })
        }
    }
}
</script>